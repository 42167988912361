var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "px-8",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 text-lg-h4 text-xl-h3 pb-8 font-weight-bold"
  }, [_vm._v(" Forgot Password ")]), _c('v-card-text', [_c('v-form', {
    ref: "forgot",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.forgotForm,
      callback: function callback($$v) {
        _vm.forgotForm = $$v;
      },
      expression: "forgotForm"
    }
  }, [_c('v-text-field', {
    staticClass: "mx-2",
    attrs: {
      "background-color": "white",
      "placeholder": "Company Email",
      "rules": _vm.emailRules
    },
    model: {
      value: _vm.companyEmail,
      callback: function callback($$v) {
        _vm.companyEmail = $$v;
      },
      expression: "companyEmail"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "flex-column"
  }, [_c('v-btn', {
    staticClass: "rounded-md text-capitalize pa-5 white--text font-weight-medium text-h6",
    attrs: {
      "width": "100%",
      "color": "#2EAC68",
      "disabled": !_vm.forgotForm
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.validateForgotForm.apply(null, arguments);
      }
    }
  }, [_vm._v(" Send Mail ")]), _c('div', {
    staticClass: "align-self-start mt-4"
  }, [_vm._v(" Back to "), _c('router-link', {
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("Login")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }